import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Lazy, Navigation } from 'swiper'
import 'swiper/css/bundle'
import styles from './style.module.scss';
import { WafFriendlyPass } from 'src/types/waf-friendly-pass';
import { Suspense } from 'react';
import dynamic from 'next/dynamic';
SwiperCore.use([Lazy])

const DynamicHotelCard = dynamic(() => import("src/components/hotel/card"), { suspense: true })

type Props = {
  hotels: WafFriendlyPass.hotel[]
}
const AboutHotelCarousel: React.VFC<Props> = ({ hotels }) => {
  return (
    <div className={`${styles.aboutHotelCarousel} aboutHotelCaroucel`}>
      <Swiper
        modules={[Lazy, Navigation]}
        observer
        slidesPerView={'auto'}
        spaceBetween={16}
        loop={true}
        loopedSlides={hotels.length + 8}
        loopAdditionalSlides={1}
        speed={1000}
        lazy={true}
        watchOverflow
        className={styles.aboutHotelCarouselContainer}
        initialSlide={3}
      >
        <Suspense fallback={`<p>Loading...</p>`}>
          {hotels.map((hotel, idx) => {
            return (
              <SwiperSlide key={`${idx}`} className={styles.aboutHotelCarouselSlide}>
                <DynamicHotelCard hotel={hotel} key={idx} />
              </SwiperSlide>
            )
          })}
        </Suspense>
      </Swiper>
    </div>
  )
}

export { AboutHotelCarousel }
