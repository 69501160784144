import { faqItems } from 'src/utils/faq'
import { FaQItem } from '../item'
import styles from './style.module.scss'

const FaQList: React.VFC = () => {
  return (
    <div className={styles.FaQList}>
      {
        faqItems.map((item, idx) => {
          return(
            <FaQItem key={idx} q={item.q} a={item.a} />
          )
        })
      }
    </div>
  )
}

export { FaQList }
