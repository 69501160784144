import React, { FC, useEffect } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import styles from './style.module.scss'
import { useIsAuthenticated } from 'src/store/userUid'
import { Routes } from 'src/utils/routes'

type Props = {
  acf: {
    [key: string]: any
  }
  login: boolean
}

export const TicketCard: FC<Props> = ({ ...props }) => {
  const colorClass = {
    backgroundColor: props.acf.backgroundColor
      ? props.acf.backgroundColor
      : '#fafafa',
    color: props.acf.textColor ? props.acf.textColor : '#222244',
  }

  return (
    <article className={styles.ticketCard} style={colorClass}>
      <Link href={props.login ? props.acf.url : Routes.MY_PAGE} passHref>
        <a target={props.login ? '_blank' : '_self'} rel="noreferrer noopener">
          <span className={styles.ticketCardLogo}>
            <Image
              src={props.acf.logo.url}
              layout="responsive"
              width={120}
              height={120}
              priority={true}
              alt={props.acf.companyName}
            />
          </span>
          <p className={styles.ticketCardCompanyName}>
            {props.acf.companyName}
          </p>
          <h2 className={styles.ticketCardValue}>{props.acf.value}</h2>
        </a>
      </Link>
    </article>
  )
}
