import Image from 'next/image'
import Link from 'next/link'
import LinkButton from 'src/components/button/LinkButton'
import { blurDataURL } from 'src/utils/blurDataURL'
import { Routes } from 'src/utils/routes'
import styles from './style.module.scss'

type Props = {}

const AboutHowToUse: React.VFC<Props> = ({}) => {
  const base64 = blurDataURL
  return (
    <section className={styles.aboutHowToUse}>
      <h2 className={styles.aboutHowToUseHeading}>ワフパスの利用方法</h2>
      <ol className={styles.aboutHowToUseList}>
        <li className={styles.aboutHowToUseListItem}>
          <div className={styles.aboutHowToUseListItemImage}>
            <Image
              blurDataURL={base64}
              placeholder="blur"
              src="/images/about_howtouse_01.svg"
              width={240}
              height={160}
              alt=""
            />
          </div>
          <div className={styles.aboutHowToUseListItemStack}>
            <h3 className={styles.aboutHowToUseListItemHeading}>
              <small>01</small>
              <b>福岡への来訪スケジュールを立てる</b>
            </h3>
            <p className={styles.aboutHowToUseListItemText}>
              まずは福岡への滞在・ワーケーションの予定を立てましょう。
              <br />
              ワフパスでは現地で利用可能な特典のほか、会員限定のお得な宿泊プランや移動などに使える交通キャンペーンも提供していますので是非チェックしてください。
              <br />
              <small>
                ※申請には無料の会員登録が必要です。 ワフパス会員登録は
                <Link href={Routes.MY_PAGE} passHref>
                  <a>こちらから</a>
                </Link>
                （所要時間2分程度）
              </small>
            </p>
          </div>
        </li>
        <li className={styles.aboutHowToUseListItem}>
          <div className={styles.aboutHowToUseListItemImage}>
            <Image
              blurDataURL={base64}
              placeholder="blur"
              src="/images/about_howtouse_02.svg"
              width={240}
              height={160}
              alt=""
            />
          </div>
          <div className={styles.aboutHowToUseListItemStack}>
            <h3 className={styles.aboutHowToUseListItemHeading}>
              <small>02</small>
              <b>専用フォームからパスを発行する</b>
            </h3>
            <p className={styles.aboutHowToUseListItemText}>
              予定や宿泊先が決まったら、現地での特典を利用するためのパスの発行を行いましょう
              <br />
              <small>
                ※パス発行手続きは
                <Link href={Routes.MY_PAGE} passHref>
                  <a>こちらから</a>
                </Link>
              </small>
              <br />
              <small>
                {' '}
                ※パス発行には、滞在日程と宿泊先となる市内宿泊施設の入力が必須となります
              </small>
            </p>
          </div>
        </li>
        <li className={styles.aboutHowToUseListItem}>
          <div className={styles.aboutHowToUseListItemImage}>
            <Image
              blurDataURL={base64}
              placeholder="blur"
              src="/images/about_howtouse_03.svg"
              width={240}
              height={160}
              alt=""
            />
          </div>
          <div className={styles.aboutHowToUseListItemStack}>
            <h3 className={styles.aboutHowToUseListItemHeading}>
              <small>03</small>
              <b>特典施設でパス画面を提示し二次元バーコードを読み込む</b>
            </h3>
            <p className={styles.aboutHowToUseListItemText}>
              ワフパス特典を利用する施設で専用の二次元バーコードを読み込むことで特典の選択画面を表示します。
            </p>
          </div>
        </li>
        <li className={styles.aboutHowToUseListItem}>
          <div className={styles.aboutHowToUseListItemImage}>
            <Image
              blurDataURL={base64}
              placeholder="blur"
              src="/images/about_howtouse_04.svg"
              width={240}
              height={160}
              alt=""
            />
          </div>
          <div className={styles.aboutHowToUseListItemStack}>
            <h3 className={styles.aboutHowToUseListItemHeading}>
              <small>04</small>
              <b>特典を選択して利用する</b>
            </h3>
            <p className={styles.aboutHowToUseListItemText}>
              二次元バーコードを読み込んだら、 利用したい特典・
              サービスを選択して「利用する」ボタンを押すことで利用が可能になります。
            </p>
            <small className={styles.aboutHowToUseListItemSmall}>
              ※1回の滞在につき、各特典は原則1回のみの利用となります。
            </small>
          </div>
        </li>
      </ol>
      <div className={styles.aboutHowToUseButton}>
        <LinkButton href={Routes.MY_PAGE} color="blue">
          パス発行はこちら
        </LinkButton>
      </div>
    </section>
  )
}

export { AboutHowToUse }
